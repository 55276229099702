<template>
  <page-content :show-search="true">
    <template #search>
      <table-search
        ref="search"
        :fields="search_fields"
        :default="search_default"
        @runSearch="runSearch"
        @changeField="searchChangeField"
      />
    </template>

    <template #default>
      <table-header
        ref="header"
        :title="title"
        :button-actions="header_actions"
        @runSearch="runSearch"
        :search-tips="$t('staff.search_tips')"
      />

      <table-content
        :per-page="page_condition.pageSize"
        :per-page-dropdown-enabled="true"
        @perPageChange="perPageChange"
        :columns="columns"
        :rows="rows"
        :total-rows="total_rows"
        :current-page="current_page"
        :action-options="table_actions"
        @runTableAction="runTableAction"
        @sortChange="sortChange"
        @pageChange="pageChange"
        :permission="view_permission"
      />

      <modal
        :title="$t('staff.reset_password')"
        :visible="show_reset_password"
        :confirm-fun="runResetPassword"
        :cancel-fun="hideResetPassword"
      >
        <b-form>
          <b-row>
            <b-col cols="12">
              <b-form-group :label="$t('staff.input_password')" label-cols="3">
                <b-form-input type="password" v-model="reset_password_data.newPassword"/>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-form-group :label="$t('staff.input_password_again')" label-cols="3">
                <b-form-input type="password" v-model="reset_password_data.newPasswordAgain"/>
              </b-form-group>
            </b-col>
          </b-row>

        </b-form>
      </modal>

    </template>

  </page-content>


</template>

<script>
import common from "@/common";
import { VBModal } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import BCardCode from '@core/components/b-card-code'
import nfcUtils from "@/views/staff/nfcUtils"

import TableContent from "@/components/TableContent";
import TableHeader from "@/components/TableHeader";
import TableSearch from "@/components/TableSearch";
import PageContent from "@/components/PageContent";
import Modal from "@/components/Modal";


export default {
  name: 'staff',
  components: {
    Modal,
    PageContent,
    TableSearch,
    TableHeader,
    TableContent,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      title: common.getMenuName('staff'),
      view_permission: common.checkPermission('Sta_List'),
      rows: [],
      total_rows: 0,
      sort_condition: {},
      current_page: 1,
      page_condition: {
        pageIndex: 0,
        pageSize: common.getTablePerPage()
      },
      show_reset_password: false,
      reset_password_data: {
        staffID: 0,
        newPassword: '',
        newPasswordAgain: ''
      },
      table_actions: [
        {
          text: 'common.edit',
          variant: 'primary',
          fun: 'editData',
          permission: 'Sta_View'
        },
        {
          text: 'staff.reset_password',
          variant: 'primary',
          fun: 'showResetPassword',
          permission: 'Sta_Edit'
        },
        {
          text: 'staff.impersonate',
          variant: 'warning',
          fun: 'impersonate',
          permission: 'Admin_Impersonate',
          show: 'this.common.isShowImpersonated()'
        },
      ],
      search_fields: [
        {
          field: 'companyID',
          label: 'common.company',
          type: 'select',
          options: [],
          show: common.isAdmin(),
          clearable: false,
        },
        {
          field: 'facilityID',
          label: 'common.facility',
          type: 'select',
          options: [],
          show: common.isAdmin(),
          clearable: false,
        },
        {
          field: 'isActive',
          label: 'staff.isActive',
          type: 'select',
          options: [
            {text: this.$t('staff.active'), value: true},
            {text: this.$t('staff.passive'), value: false},
          ]
        },
      ],
      search_default: {
        companyID: common.getCompanyId(),
        facilityID: common.getFacilityId(),
      },
      isShowDetailCols: common.getCustomConfigs().isShowDetailCols,
    }
  },

  computed: {
    columns() {
      const origin =  [
        {
          label: 'common.row_index',
          field: 'row_index',
          sortable: false,
          width: '50px',
        },
        {
          label: 'staff.staffCode',
          field: 'staffCode',
          width: this.isShowDetailCols ? '160px' : '220px',
        },
        {
          label: 'staff.chineseName',
          field: 'chineseName',
          width: this.isShowDetailCols ? '160px' : '220px',
        },
        {
          label: 'staff.isActive',
          field: 'isActive',
          width: this.isShowDetailCols ? '80px' : 'auto',
          change: function (isActive) {
            return isActive ? common.getI18n('staff.active') : common.getI18n('staff.passive')
          }
        },
        {
          label: 'staff.login_nfc',
          field: 'loginNfcs',
          colType: 'detail',
          changeHTML: (raw, _row) => {
            const valArr = raw ? raw.split(',') : []
            return valArr.map(val => `<span class="badge badge-light-secondary wh-font-family-monospace mr-05 font-weight-normal wh-user-select-all">${nfcUtils.getConvertedNfc(val)}</span>`)
              .join('')
          }
        },
        {
          label: 'staff.call_bell_nfc',
          field: 'callBellNfcs',
          colType: 'detail',
          changeHTML: (raw, _row) => {
            const valArr = raw ? raw.split(',') : []
            return valArr.map(val => `<span class="badge badge-light-secondary wh-font-family-monospace mr-05 font-weight-normal wh-user-select-all">${nfcUtils.getConvertedNfc(val)}</span>`)
              .join('')
          }
        },
        {
          label: 'common.action',
          field: 'action',
          width: common.isShowImpersonated() ? '320px' : '220px',
          sortable: false,
        },
      ]
      return this.isShowDetailCols
        ? origin.filter(col => col.colType !== 'blank')
        : origin.filter(col => col.colType !== 'detail')
    },
    header_actions() {
      return [
        // {
        //   text: common.getI18n('common.show_more'),
        //   variant: this.isShowDetailCols ? 'info' : 'outline-info',
        //   fun: this.toggleDetailCols,
        //   permission: 'Sta_List',
        //   pill: true,
        // },
        {
          text: 'common.add',
          variant: 'primary',
          fun: this.addData,
          permission: 'Sta_Create'
        },
      ]
    },
  },

  mounted() {
    if (!common.isAdmin()) {
      this.getList()
    } else {
      common.getSearchCompanyOptions('companyID', this)
      common.getSearchFacilityOptions('facilityID', common.getAdminCompanyId(), this)
      this.getList()
    }
  },

  methods: {
    getList: function () {
      if (!this.view_permission) return

      // const api = '/staffmanage/GetStaffList'
      const api = '/staffmanage/GetStaffListWithNfc'
      const search = this.getSearchCondition()

      const url = common.getTableUrl(api, search, this.sort_condition, this.page_condition)

      common.apiGetData(url).then(res => {
        this.total_rows = res.data.total
        this.rows = res.data.staffs
      })
    },
    perPageChange(size) {
      this.page_condition.pageIndex = 0
      this.page_condition.pageSize = size
      common.setTablePerPage(size)
      this.getList()
    },
    addData: function () {
      this.$router.push({name: 'staff_add'})
    },
    editData: function (data) {
      this.$router.push({name: 'staff_edit', params: {id: common.encrypt(data.staffID)}})
    },
    deleteData: function (data) {
      const that = this

      common.showConfirm(this.$t('common.delete_tips')+data.chineseName, '', function () {
        common.apiPostData('/staffmanage/DeleteStaff?staffID='+data.staffID).then(res => {
          common.showToast(that.$t('common.delete_success'))
          that.getList()
        })

      })
    },

    runSearch: function () {
      this.current_page = 1
      this.page_condition.pageIndex = 0
      const vmOfSearch = this.$refs.search
      common.syncSearchFields({
        companyID: vmOfSearch.list.companyID,
        facilityID: vmOfSearch.list.facilityID,
        floorID: vmOfSearch.list.floorID,
        roomID: vmOfSearch.list.roomID,
      })
      this.getList()
    },
    async searchChangeField(field, value) {
      await common.searchChangeField({
        fieldName: field,
        newValue: value,
        currentVm: this,
        searchVm: this.$refs.search,
        isSyncGlobally: false
      })
    },

    runTableAction: function (action, data) {
      this[action](data)
    },
    sortChange: function (value) {
      this.sort_condition = value
      this.getList()
    },
    getSearchCondition: function () {
      this.$refs.search.buildSearchCondition()

      let where = {}
      where['searchContent'] = this.$refs.header.searchContent
      where = Object.assign(where, this.$refs.search.search_condition)

      return where
    },
    pageChange: function (value) {
      this.current_page = value
      this.page_condition.pageIndex = value - 1
      this.getList()
    },
    showResetPassword: function (data) {
      this.show_reset_password = true
      this.reset_password_data.staffID = data.staffID
    },
    hideResetPassword: function () {
      this.show_reset_password = false
      this.reset_password_data.staffID = 0
      this.reset_password_data.newPassword = ''
      this.reset_password_data.newPasswordAgain = ''
    },
    runResetPassword: function () {
      if (!this.reset_password_data.newPassword.trim() || !this.reset_password_data.newPasswordAgain) {
        common.showError('NewPasswordRequired')
      }

      if (this.reset_password_data.newPassword !== this.reset_password_data.newPasswordAgain) {
        common.showError('NewPasswordNotSame')
      }

      const that = this
      common.apiPostData('/staffmanage/EditStaffPassword', this.reset_password_data).then(res => {
        common.showToast(that.$t('staff.reset_success'))
        that.hideResetPassword()
        that.getList()
      })
    },
    impersonate: function (data) {
      common.showConfirm(common.getI18n('staff.impersonate_confirm')+data.chineseName, '', function () {
        common.apiPostData('/admin/StartImpersonate?targetStaffID='+data.staffID).then(res => {
          common.showAlert(common.getI18n('staff.impersonate_after'), '', function () {
            common.logout()
          })
        })
      })
    },
    toggleDetailCols() {
      this.isShowDetailCols = !this.isShowDetailCols
      common.assignCustomConfigs({ isShowDetailCols: this.isShowDetailCols })
      console.log(this.isShowDetailCols)
    },
  }
}
</script>
